// Libs
import { configureStore } from '@reduxjs/toolkit';

// Slices
import apiSlice from '@/slices/apiSlice';

export default function makeStore() {
  return configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
  });
}
