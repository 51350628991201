'use client';

// Libraries
import { SvgIcon } from '@mui/material';

export default function PhoneIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5547 9.83205C9.8329 9.64658 10 9.33435 10 9V5C10 3.34315 8.65685 2 7 2H3C2.44772 2 2 2.44772 2 3C2 12.9411 11.0589 22 21 22C21.5523 22 22 21.5523 22 21V17C22 15.3431 20.6569 14 19 14H15C14.6656 14 14.3534 14.1671 14.1679 14.4453L12.2626 17.3033C10.1874 15.9615 8.36925 14.1892 6.97074 12.149L6.69668 11.7374L9.5547 9.83205ZM15.5352 16H19C19.5523 16 20 16.4477 20 17V19.9653C18.0627 19.8313 16.1721 19.3132 14.4087 18.4916L14.0041 18.2966L15.5352 16ZM4.03467 4H7C7.55228 4 8 4.44772 8 5V8.46482L5.70337 9.9959C4.76882 8.11845 4.17903 6.08626 4.03467 4Z"
          fill="#8A8686"
        />
      </svg>
    </SvgIcon>
  );
}
