// Libs
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.emailServiceUrl,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
  },
  validateStatus: (response, result) =>
    response.status === 200 && result.success,
});

export default createApi({
  baseQuery,
  tagTypes: ['Email', 'Recaptcha'],
  endpoints: () => ({}),
});
