export const LANGUAGE = {
  VIETNAMESE: 'vi',
  ENGLISH: 'en',
  JAPANESE: 'ja',
};

export const PAGE = {
  HOTEL_MANAGEMENT: 'hotel-management',
  FOOD_AND_BEVERAGE_MANAGEMENT: 'food-and-beverage-management',
  CHANNEL_MANAGER: 'channel-manager',
  PURCHASING_MANAGEMENT: 'purchasing-management',
  ACCOUNTING_MANAGEMENT: 'accounting-management',
  HUMAN_RESOURCE_MANAGEMENT: 'human-resource-management',
  HOTEL_BOOKING_ONLINE: 'hotel-booking-online',
  AMUSEMENT_PARK_MANAGEMENT: 'amusement-park-management',
  MINERAL_BATH_MANAGEMENT: 'mineral-bath-management',
  CUSTOMER_RELATIONSHIP_MANAGEMENT: 'customer-relationship-management',
  TECHNICAL: 'technical',
  NEWS: {
    LIST: 'news',
    DA_LAT_COLLEGE:
      'dihotel-awarded-app-sw-worth-2-billion-vnd-to-da-lat-college',
    YANOLJA: 'korean-unicorn-yanolja-partners-with-dihotel',
    PHAN_THIET_UNIVERSITY:
      'dihotel-sponsors-hotel-training-sw-for-phan-thiet-university',
    ATM_TECHCOM: 'dihotel-signs-a-cooperation-agreement-with-atm-techcom',
    NHA_TRANG_TOURISM_COLLEGE:
      'dihotel-awarded-app-sw-worth-2-billion-vnd-to-nha-trang-tourism-college',
    HONG_BANG_INTERNATIONAL_UNIVERSITY:
      'dihotel-awarded-app-sw-worth-2-billion-vnd-to-hong-bang-international-university',
    DONG_A_UNIVERSITY:
      'dihotel-awarded-app-sw-worth-2-billion-vnd-to-dong-a-university',
  },
  ABOUT_US: 'about-us',
  NOT_FOUND: '404',
  INTERNAL_ERROR: '500',
  HELP_PORTAL: 'help-portal',
  DICLOUD: 'dicloud',
};

export const LINK = {
  FACEBOOK: 'https://www.facebook.com/dihotel.vn',
  FACEBOOK_MESSENGER: 'https://www.messenger.com/t/1312673512165171/',
  YOUTUBE: 'https://www.youtube.com/@DiHotel-VN',
  ZALO: 'https://zalo.me/4122464337402601807',
  MAP: {
    MAIN: 'https://www.google.com/maps/place/TDA+Solution+(DiHotel)/@10.775296,106.6282416,15.25z/data=!4m6!3m5!1s0x31752f14a2e4296f:0xb6ec1c09847c164e!8m2!3d10.7750324!4d106.6326108!16s%2Fg%2F11stvfcns2?entry=ttu',
    BRANCH1:
      'https://www.google.com/maps/place/DiHotel+PMS/@12.2876345,109.2030121,17.75z/data=!4m6!3m5!1s0x317067ffdc81f15b:0xe2fc0f48c7496dda!8m2!3d12.2876016!4d109.2042691!16s%2Fg%2F11rk02jqh_?entry=ttu',
    BRANCH2:
      'https://www.google.com/maps/place/84+%C4%90.+C%E1%BB%95+Loa,+Ph%C6%B0%E1%BB%9Dng+2,+Th%C3%A0nh+ph%E1%BB%91+%C4%90%C3%A0+L%E1%BA%A1t,+L%C3%A2m+%C4%90%E1%BB%93ng,+Vi%E1%BB%87t+Nam/@11.9532042,108.4338174,18.5z/data=!4m6!3m5!1s0x317112d6972c175d:0x9c5dd6076b17117d!8m2!3d11.9532016!4d108.4344387!16s%2Fg%2F11gh_6zkxj?entry=ttu',
  },
  NHA_TRANG_TOURISM_COLLEGE_VIDEO: 'https://youtu.be/TldbCq-bPTA',
  HONG_BANG_INTERNATIONAL_UNIVERSITY_VIDEO: 'https://youtu.be/ev0YCL6U79o',
  DIHOTEL_VIDEO: 'https://youtu.be/B0kYlbSkwlI',
  PAGE: {
    HOTEL_MANAGEMENT: '/hotel-management',
    FOOD_AND_BEVERAGE_MANAGEMENT: '/food-and-beverage-management',
    CHANNEL_MANAGER: '/channel-manager',
    PURCHASING_MANAGEMENT: '/purchasing-management',
    ACCOUNTING_MANAGEMENT: '/accounting-management',
    HUMAN_RESOURCE_MANAGEMENT: '/human-resource-management',
    HOTEL_BOOKING_ONLINE: '/hotel-booking-online',
    AMUSEMENT_PARK_MANAGEMENT: '/amusement-park-management',
    MINERAL_BATH_MANAGEMENT: '/mineral-bath-management',
    CUSTOMER_RELATIONSHIP_MANAGEMENT: '/customer-relationship-management',
    TECHNICAL: '/technical',
    HELP_PORTAL: '/help-portal',
    NEWS: {
      LIST: '/news',
      DA_LAT_COLLEGE:
        '/news/dihotel-awarded-app-sw-worth-2-billion-vnd-to-da-lat-college',
      YANOLJA: '/news/korean-unicorn-yanolja-partners-with-dihotel',
      PHAN_THIET_UNIVERSITY:
        '/news/dihotel-sponsors-hotel-training-sw-for-phan-thiet-university',
      ATM_TECHCOM:
        '/news/dihotel-signs-a-cooperation-agreement-with-atm-techcom',
      NHA_TRANG_TOURISM_COLLEGE:
        '/news/dihotel-awarded-app-sw-worth-2-billion-vnd-to-nha-trang-tourism-college',
      HONG_BANG_INTERNATIONAL_UNIVERSITY:
        '/news/dihotel-awarded-app-sw-worth-2-billion-vnd-to-hong-bang-international-university',
      DONG_A_UNIVERSITY: ['', PAGE.NEWS.LIST, PAGE.NEWS.DONG_A_UNIVERSITY].join(
        '/'
      ),
    },
    ABOUT_US: '/about-us',
    HOME: '/',
    DICLOUD: 'https://dicloud.vn/',
  },
  PARTNERS: {
    BIDV: 'https://bidv.com.vn/',
    VN_PAY: 'https://vnpay.vn/',
    ZALO_PAY: 'https://zalopay.vn/',
    MOMO: 'https://momo.vn/',
    YANOLJA: 'https://www.yanolja.com/',
    SWEETSOFT: 'https://www.sweetsoft.vn/',
    ATM_ACADEMY: 'https://daotaonghedulich.com/',
    PHAN_THIET_UNIVERSITY: 'https://upt.edu.vn/',
    DA_LAT_COLLEGE: 'https://cddl.edu.vn/',
    NHA_TRANG_TOURISM_COLLEGE: 'http://ntc.edu.vn/',
    HONG_BANG_INTERNATIONAL_UNIVERSITY: 'https://hiu.vn/',
    DONG_A_UNIVERSITY: 'https://donga.edu.vn/',
  },
  CHANNEL_MANAGER: {
    SITE_MINDER: 'https://www.siteminder.com/',
    HOTEL_LINK: 'https://www.hotellinksolutions.com/',
    D_EDGE: 'https://www.d-edge.com/',
  },
};

export const LAYOUT = {
  HEADER_TRANSPARENT: 'LayoutHeaderTransparent',
  HEADER_NORMAL: 'LayoutHeaderNormal',
};

export const GA = {
  MEASUREMENT_ID: 'G-11PW1K816Y',
};

export const API_END_POINTS = {
  SEND_REGISTER_DEMO_EMAIL: '/email/sendRegisterDemoDiHotel',
  SEND_CONTACT_EMAIL: '/email/sendContactDiHotel',
};
