// Constants
import { API_END_POINTS } from '@/constants/config';

// Slice
import apiSlice from './apiSlice';

export const emailApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendContactEmail: builder.mutation({
      query: (body) => ({
        url: API_END_POINTS.SEND_CONTACT_EMAIL,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Email'],
    }),
    sendRegisterDemoEmail: builder.mutation({
      query: (body) => ({
        url: API_END_POINTS.SEND_REGISTER_DEMO_EMAIL,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Email'],
    }),
  }),
  overrideExisting: true,
});

export const { useSendContactEmailMutation, useSendRegisterDemoEmailMutation } =
  emailApiSlice;
