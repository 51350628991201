const en = require('./translations.en.json');
const vi = require('./translations.vi.json');
const ja = require('./translations.ja.json');

const i18n = {
  translations: {
    vi: vi,
    en: en,
    ja: ja,
  },
  defaultLang: 'vi',
  useBrowserDefault: false,
};

module.exports = i18n;
