'use client';

// Libraries
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Inter } from 'next/font/google';
import { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';

// Customize
import LayoutHeaderNormal from '@/components/Layouts/HeaderNormal';
import LayoutHeaderTransparent from '@/components/Layouts/HeaderTransparent';

//Style sheet
import '@/styles/App.scss';
import 'animate.css';

// Stores
import makeStore from '@/lib/store';

// Variables
const layouts = {
  LayoutHeaderNormal: LayoutHeaderNormal,
  LayoutHeaderTransparent: LayoutHeaderTransparent,
};
const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: inter.style.fontFamily,
    },
  },
});
const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('wow.js') : null;

export default function App({ Component, pageProps }) {
  const storeRef = useRef(null);
  if (!storeRef.current) {
    storeRef.current = makeStore();
  }

  const Layout = layouts[Component.layout] || ((children) => <>{children}</>);

  useEffect(() => {
    new WOW().init();
  }, []);

  return (
    <Provider store={storeRef.current}>
      <ThemeProvider theme={theme}>
        <Layout
          canonical={Component.canonical || process.env.hostName}
          title={Component.title}
          description={Component.description}
          image={Component.image}
          imageType={Component.imageType || undefined}
          imageWidth={Component.imageWidth || undefined}
          imageHeight={Component.imageHeight || undefined}>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </Provider>
  );
}
