'use client';

// Libraries
import { SvgIcon } from '@mui/material';

export default function EmailIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9962 11.7201C21.8479 6.32667 17.4292 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C13.4515 22 14.8612 21.6899 16.1542 21.0987C16.6564 20.8691 16.8774 20.2757 16.6478 19.7734C16.4181 19.2712 15.8248 19.0502 15.3225 19.2798C14.2893 19.7523 13.1631 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12V13L19.9947 13.1689C19.9282 14.2246 19.2448 15 18.5 15C17.716 15 17 14.1408 17 13V12L16.9954 11.7831C16.8818 9.12231 14.6888 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C13.4582 17 14.7706 16.3758 15.6839 15.3803C16.314 16.357 17.3269 17 18.5 17C20.4774 17 22 15.1729 22 13V12L21.9962 11.7201ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"
          fill="#979292"
        />
      </svg>
    </SvgIcon>
  );
}
