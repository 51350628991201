'use client';

// Libs
import React from 'react';
import { ReCaptchaProvider } from 'next-recaptcha-v3';

export default function RecaptchaProvider({
  children,
  useEnterprise,
  language,
}) {
  return (
    <ReCaptchaProvider useEnterprise={!!useEnterprise} language={language}>
      {children}
    </ReCaptchaProvider>
  );
}
