'use client';

// Libs
import { Inter } from 'next/font/google';
import { Modal } from 'react-bootstrap';

// Components
import RequestDemo from '@/components/RequestDemo';

const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });

export default function ContactUsModal({ isShowModal, setIsShowModal }) {
  return (
    <Modal
      dialogClassName={inter.className + ' contact-us-modal-wrapper modal-50w'}
      show={isShowModal}
      onHide={() => setIsShowModal(false)}
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <RequestDemo />
      </Modal.Body>
    </Modal>
  );
}
